:root {
  --_7nmj7r0: #1A1A1A;
  --_7nmj7r1: #262626;
  --_7nmj7r2: #333333;
  --_7nmj7r3: #404040;
  --_7nmj7r4: #4D4D4D;
  --_7nmj7r5: #595959;
  --_7nmj7r6: #666666;
  --_7nmj7r7: #737373;
  --_7nmj7r8: #808080;
  --_7nmj7r9: #8C8C8C;
  --_7nmj7ra: #999999;
  --_7nmj7rb: #A6A6A6;
  --_7nmj7rc: #B3B3B3;
  --_7nmj7rd: #BFBFBF;
  --_7nmj7re: #CCCCCC;
  --_7nmj7rf: #D9D9D9;
  --_7nmj7rg: #E6E6E6;
  --_7nmj7rh: #F2F2F2;
  --_7nmj7ri: #02BB61;
  --_7nmj7rj: #02A757;
  --_7nmj7rk: #02914C;
  --_7nmj7rl: #00B9D2;
  --_7nmj7rm: #00A2B8;
  --_7nmj7rn: #D9E7EF;
  --_7nmj7ro: #F21807;
  --_7nmj7rp: #131313;
  --_7nmj7rq: #D9D9D9;
  --_7nmj7rr: #F8F8F8;
  --_7nmj7rs: #FFFFFF;
  --_7nmj7rt: 1;
  --_7nmj7ru: 10;
  --_7nmj7rv: 20;
  --_7nmj7rw: 99;
  --_7nmj7rx: 100;
  --_7nmj7ry: 105;
  --_7nmj7rz: 200;
  --_7nmj7r10: 300;
  --_7nmj7r11: 8888;
  --_7nmj7r12: 0px;
  --_7nmj7r13: 2px;
  --_7nmj7r14: 4px;
  --_7nmj7r15: 6px;
  --_7nmj7r16: 8px;
  --_7nmj7r17: 10px;
  --_7nmj7r18: 12px;
  --_7nmj7r19: 14px;
  --_7nmj7r1a: 16px;
  --_7nmj7r1b: 18px;
  --_7nmj7r1c: 20px;
  --_7nmj7r1d: 22px;
  --_7nmj7r1e: 24px;
  --_7nmj7r1f: 28px;
  --_7nmj7r1g: 30px;
  --_7nmj7r1h: 32px;
  --_7nmj7r1i: 36px;
  --_7nmj7r1j: 40px;
  --_7nmj7r1k: 48px;
  --_7nmj7r1l: 52px;
  --_7nmj7r1m: 56px;
  --_7nmj7r1n: 60px;
  --_7nmj7r1o: 64px;
  --_7nmj7r1p: 72px;
  --_7nmj7r1q: 80px;
  --_7nmj7r1r: 96px;
  --_7nmj7r1s: 100px;
  --_7nmj7r1t: 120px;
  --_7nmj7r1u: 124px;
  --_7nmj7r1v: 128px;
  --_7nmj7r1w: 140px;
  --_7nmj7r1x: 144px;
  --_7nmj7r1y: 152px;
  --_7nmj7r1z: 160px;
  --_7nmj7r20: 168px;
  --_7nmj7r21: 180px;
  --_7nmj7r22: 192px;
  --_7nmj7r23: 240px;
  --_7nmj7r24: 288px;
  --_7nmj7r25: 290px;
  --_7nmj7r26: 378px;
  --_7nmj7r27: 416px;
  --_7nmj7r28: 2000px;
  --_7nmj7r29: 128px;
  --_7nmj7r2a: 60px;
  --_7nmj7r2b: 54px;
  --_7nmj7r2c: 343px;
  --_7nmj7r2d: 100px;
  --_7nmj7r2e: 61px;
  --_7nmj7r2f: 28px;
  --_7nmj7r2g: 44px;
  --_7nmj7r2h: 24px;
  --_7nmj7r2i: 24px;
  --_7nmj7r2j: 36px;
  --_7nmj7r2k: 4px;
  --_7nmj7r2l: 8px;
  --_7nmj7r2m: 16px;
  --_7nmj7r2n: 24px;
  --_7nmj7r2o: 9999px;
  --_7nmj7r2p: 5px;
  --_7nmj7r2q: 4px;
  --_7nmj7r2r: 15px;
  --_7nmj7r2s: 300ms;
}
._1rfrkfe3 {
  font-size: inherit;
  & span {
    color: var(--_1rfrkfe0);
    background-color: var(--_1rfrkfe1);
  }
}
._1pkyc70 {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100vh;
}
._1pkyc71 {
  height: 100%;
  background-color: var(--_7nmj7rr);
}
.s1uq990 {
  height: 300vh;
  background-color: var(--_7nmj7rr);
}
._4kmjls0:focus-visible {
  outline: var(--_7nmj7rp) solid 1px;;
  outline-offset: 2px;
}
.pfiybq0 {
  font-weight: 700;
}
.pfiybq1 {
  font-weight: 500;
}
.pfiybq2 {
  font-weight: 400;
}
.pfiybq3 {
  font-family: var(--font-heading);
  font-size: 48px;
  line-height: 0.9;
  letter-spacing: -0.03em;
}
.pfiybq7 {
  font-family: var(--font-heading);
  font-size: 40px;
  line-height: 0.9;
  letter-spacing: -0.015em;
}
.pfiybqb {
  font-family: var(--font-heading);
  font-size: 40px;
  line-height: 0.95;
  letter-spacing: -0.015em;
}
.pfiybqf {
  font-family: var(--font-heading);
  font-size: 33px;
  line-height: 1;
  letter-spacing: -0.015em;
}
.pfiybqj {
  font-family: var(--font-heading);
  font-size: 28px;
  line-height: 1.05;
  letter-spacing: -0.02em;
}
.pfiybqn {
  font-family: var(--font-heading);
  font-size: 23px;
  line-height: 1;
  letter-spacing: 0;
}
.pfiybqr {
  font-family: var(--font-body);
  font-size: 19px;
  line-height: 1.05;
  letter-spacing: -0.02em;
}
.pfiybqv {
  font-family: var(--font-body);
  font-size: 16px;
  line-height: 1.32;
  letter-spacing: -0.02em;
}
.pfiybqz {
  font-family: var(--font-body);
  font-size: 13.5px;
  line-height: 1.1;
  letter-spacing: -0.02em;
}
.pfiybq13 {
  font-family: var(--font-body);
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}
.pfiybq17 {
  font-family: var(--font-body);
  font-size: 11px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}
@media (min-width: 1300px) {
  .pfiybq3 {
    font-size: 101px;
  }
  .pfiybq7 {
    font-size: 76px;
  }
  .pfiybqb {
    font-size: 76px;
  }
  .pfiybqf {
    font-size: 57px;
  }
  .pfiybqj {
    font-size: 43px;
  }
  .pfiybqn {
    font-size: 32px;
  }
  .pfiybqr {
    font-size: 24px;
  }
  .pfiybqv {
    font-size: 18px;
  }
  .pfiybqz {
    font-size: 14px;
  }
  .pfiybq13 {
    font-size: 14px;
  }
  .pfiybq17 {
    font-size: 10px;
  }
}
.ofn3qg0 {
  display: grid;
  grid-gap: 64px;
  gap: 64px;
  grid-auto-columns: 1fr;
}
@media (min-width: 1000px) {
  .ofn3qg0 {
    grid-template-columns: repeat(3, 1fr);
  }
}
._11qnqgt5 {
  color: var(--_11qnqgt1);
  border: 1px solid var(--_11qnqgt2);
  height: var(--_11qnqgt3);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px var(--_11qnqgt4);
  border-radius: var(--_7nmj7r2p);
  box-shadow: 0px 0px 0px 0px transparent;
  position: relative;
  z-index: 1;
}
._11qnqgt5::before {
  content: "";
  background-color: var(--_11qnqgt0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: calc(var(--_7nmj7r2p) - 2px);
  transition: clip-path 0.2s ease-out;
  clip-path: inset(-1px -1px 0px 0px);
}
._11qnqgt5:disabled {
  opacity: 0.5;
}
._11qnqgt6 {
  --_11qnqgt3: var(--_7nmj7r2f);
  --_11qnqgt4: var(--_7nmj7r18);
}
._11qnqgt7 {
  --_11qnqgt3: var(--_7nmj7r2g);
  --_11qnqgt4: var(--_7nmj7r17);
}
._11qnqgt8 {
  --_11qnqgt0: var(--_7nmj7rl);
  --_11qnqgt1: var(--_7nmj7rp);
  --_11qnqgt2: var(--_7nmj7rl);
}
._11qnqgt9 {
  --_11qnqgt0: var(--_7nmj7rs);
  --_11qnqgt1: var(--_7nmj7rp);
  --_11qnqgt2: var(--_7nmj7rs);
}
._11qnqgte {
  display: inline-block;
  color: var(--_11qnqgta);
  -webkit-text-decoration: underline solid currentColor;
          text-decoration: underline solid currentColor;
  text-underline-offset: 7px;
  text-decoration-thickness: 1px;
  padding-bottom: 3px;
  width: -moz-fit-content;
  width: fit-content;
}
._11qnqgte:hover {
  color: var(--_11qnqgtc);
  border-color: var(--_11qnqgtd);
}
._11qnqgtf {
  --_11qnqgta: var(--_7nmj7rp);
  --_11qnqgtb: var(--_7nmj7rp);
  --_11qnqgtc: var(--_7nmj7rl);
  --_11qnqgtd: var(--_7nmj7rl);
}
._11qnqgtg {
  --_11qnqgta: var(--_7nmj7ri);
  --_11qnqgtb: var(--_7nmj7ri);
  --_11qnqgtc: var(--_7nmj7rp);
  --_11qnqgtd: var(--_7nmj7rp);
}
._11qnqgth {
  --_11qnqgta: var(--_7nmj7rp);
  --_11qnqgtb: var(--_7nmj7rp);
  --_11qnqgtc: var(--_7nmj7rs);
  --_11qnqgtd: var(--_7nmj7rs);
}
._11qnqgti {
  --_11qnqgta: var(--_7nmj7rs);
  --_11qnqgtb: var(--_7nmj7rs);
  --_11qnqgtc: var(--_7nmj7rs);
  --_11qnqgtd: var(--_7nmj7rs);
}
._11qnqgtj {
  color: currentColor;
  transition: opacity 0.2s;
}
._11qnqgtk {
  color: var(--_7nmj7rp);
  -webkit-text-decoration: underline solid currentColor;
          text-decoration: underline solid currentColor;
  text-underline-offset: 14px;
  padding-bottom: 9px;
  text-decoration-thickness: 2px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
._11qnqgtk:hover {
  color: var(--_7nmj7ro);
}
._11qnqgtl {
  display: inline-flex;
}
._11qnqgtm {
  margin-left: var(--_7nmj7r18);
}
._11qnqgtn {
  margin-left: var(--_7nmj7r15);
}
._11qnqgto {
  display: none;
}
._11qnqgtp {
  font-weight: bold;
  color: var(--_7nmj7rp);
  text-decoration: underline;
  text-underline-offset: 2px;
  word-break: break-word;
}
._11qnqgtq:hover {
  color: var(--_7nmj7rl);
}
._11qnqgtr:hover {
  color: var(--_7nmj7rs);
}
._11qnqgts {
  font-weight: bold;
  color: var(--_7nmj7ri);
  text-decoration: underline;
  text-underline-offset: 5px;
  word-break: break-word;
}
._11qnqgts:hover {
  color: var(--_7nmj7rp);
}
._11qnqgtt {
  cursor: pointer;
}
._11qnqgtt:disabled {
  pointer-events: none;
}
._11qnqgtw {
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, -webkit-text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s, -webkit-text-decoration-color 0.2s;
}
._11qnqgtx {
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, -webkit-text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s, -webkit-text-decoration-color 0.2s;
}
._11qnqgty {
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, -webkit-text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s, -webkit-text-decoration-color 0.2s;
}
._11qnqgtz {
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, -webkit-text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s, -webkit-text-decoration-color 0.2s;
}
._11qnqgt12 {
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, -webkit-text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s, -webkit-text-decoration-color 0.2s;
}
._11qnqgt13 {
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, -webkit-text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s, -webkit-text-decoration-color 0.2s;
}
._11qnqgt15 {
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, -webkit-text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s;
  transition: color 0.2s, background 0.2s, border 0.2s, box-shadow 0.2s, text-decoration-color 0.2s, -webkit-text-decoration-color 0.2s;
}
@media (hover: hover) {
  ._11qnqgt5:hover::before, ._11qnqgt5:focus-visible::before, ._11qnqgt5:active::before {
    clip-path: inset(4px 4px 4px 4px round calc(var(--_7nmj7r2p) - 2px));
  }
  ._11qnqgtj:hover {
    opacity: 0.7;
    transition: opacity 0.2s;
  }
}
@media (min-width: 740px) {
  ._11qnqgto {
    display: inline-flex;
  }
}
.pz4gip0 {
  display: flex;
}
._61idxk0 {
  color: var(--_7nmj7rs);
  display: flex;
  justify-content: center;
  background: var(--_7nmj7ro);
  padding-top: var(--_7nmj7r16);
  padding-bottom: var(--_7nmj7r16);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: var(--_7nmj7rz);
}
._61idxk1 {
  display: grid;
  width: auto;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-gap: var(--_7nmj7r16);
  gap: var(--_7nmj7r16);
  color: #FFF;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, menu, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}
*[hidden] {
  display: none;
}
body {
  line-height: 1;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
a:focus, button:focus, input:focus, select:focus, div:focus {
  outline: none;
}
html {
  background-color: white;
  scroll-behavior: smooth;
  scroll-padding: 100px;
}
body {
  background-color: white;
  -webkit-font-smoothing: antialiased;
}
body.ReactModal__Body--open {
  overflow: hidden;
}
input[type='search']::-webkit-search-decoration, input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-results-button, input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
          appearance: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
  margin: 0;
  background-color: unset;
}
input:focus-visible + label  {
  outline: rgba(0,113,227,0.5) solid 3px;;
  outline-offset: 2px;
}
_::-webkit-full-page-media, _:future, :root button:focus, :root a:focus {
  outline: rgba(0,113,227,0.5) solid 3px;;
  outline-offset: 2px;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
video {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
}
@font-face {
font-family: '__fontHeading_691388';
src: url(/_next/static/media/b7c12a1700a3d9d7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__fontHeading_691388';
src: url(/_next/static/media/e2e7c5523b8e6896-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__fontHeading_691388';
src: url(/_next/static/media/af802dbbbb15cb26-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__fontHeading_Fallback_691388';src: local("Arial");ascent-override: 113.96%;descent-override: 36.96%;line-gap-override: 0.00%;size-adjust: 97.40%
}.__className_691388 {font-family: '__fontHeading_691388', '__fontHeading_Fallback_691388', sans-serif
}.__variable_691388 {--font-heading: '__fontHeading_691388', '__fontHeading_Fallback_691388', sans-serif
}

@font-face {
font-family: '__fontBody_2ca7e9';
src: url(/_next/static/media/6ce8666212309fa2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__fontBody_2ca7e9';
src: url(/_next/static/media/42ad61a0a8a876a7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__fontBody_2ca7e9';
src: url(/_next/static/media/4e8fa1e4cdfb71a2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__fontBody_Fallback_2ca7e9';src: local("Arial");ascent-override: 103.85%;descent-override: 33.68%;line-gap-override: 0.00%;size-adjust: 106.88%
}.__className_2ca7e9 {font-family: '__fontBody_2ca7e9', '__fontBody_Fallback_2ca7e9'
}.__variable_2ca7e9 {--font-body: '__fontBody_2ca7e9', '__fontBody_Fallback_2ca7e9'
}

._5kjj9n0 {
  position: relative;
}
._5kjj9n1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
._5kjj9n4 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
._1o7y00k0 {
  position: relative;
}
._1o7y00k1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
._1o7y00k4 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
._70z7ze0 {
  position: relative;
  background: black;
}
._70z7ze1 {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
._70z7ze5 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.ztkwrr0 {
  position: relative;
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 100%;
}
.ztkwrr2 {
  opacity: 1;
}
.ztkwrr3 {
  opacity: 0;
}
._1ukton30 {
  display: grid;
  border: none;
}
._1ukton31 {
  display: none;
}
._1ukton32 {
  display: grid;
}
._1ukton33 {
  position: relative;
  display: grid;
  border: none;
}
._1ukton34 {
  width: 100%;
  height: 100%;
}
@media (min-width: 740px) {
  ._1ukton31 {
    display: grid;
  }
  ._1ukton32 {
    display: none;
  }
}
.p4off1 {
  --p4off0: 0px;
}
.p4off2 {
  display: block;
  margin-bottom: var(--p4off0);
}
.p4off2:last-child {
  margin-bottom: 0;
}
.p4off3 {
  --p4off0: 10px;
}
._1d7hzft0 {
  display: grid;
  text-align: left;
  -webkit-tap-highlight-color: transparent;
}
._1d7hzft0[disabled] {
  cursor: unset;
}
._1d7hzft1 {
  border-bottom: 1px solid var(--_7nmj7rp);
}
._1d7hzft2 {
  margin-block-start: var(--_7nmj7r1e);
  margin-block-end: var(--_7nmj7r1k);
}
._1d7hzft3 {
  display: block;
}
._1d7hzft4 {
  display: none;
}
.d8wjhg0 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-block: var(--_7nmj7r1e);
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}
.d8wjhg1 {
  color: var(--_7nmj7rp);
}
.d8wjhg2 {
  width: 5px;
  height: 12px;
  margin-inline: var(--_7nmj7r1a);
  flex-shrink: 0;
}
.d8wjhg0:hover .d8wjhg2 {
  transform: rotate(90deg);
}
.d8wjhg3 {
  transform: rotate(90deg);
}
@media (min-width: 740px) {
  .d8wjhg0 {
    align-items: center;
    padding-block: var(--_7nmj7r1b);
  }
  .d8wjhg2 {
    width: 12px;
    height: 22px;
    margin-inline: var(--_7nmj7r1c);
    transition: transform 0.2s ease-out;
  }
}
._1ssj7iu0 {
  display: grid;
}
.rq8dya2 {
  margin-bottom: var(--_7nmj7r1e);
}
.rq8dya4 {
  font-style: italic;
}
.rq8dya5 {
  text-decoration: underline;
}
.rq8dya6 {
  font-weight: bold;
}
.rq8dya7 {
  color: var(--_7nmj7r4);
}
.rq8dya8 {
  margin-bottom: var(--_7nmj7r1e);
  padding-left: var(--_7nmj7r1e);
}
li .rq8dya8 {
  margin-bottom: var(--_7nmj7r14);
}
.rq8dya9 {
  margin-bottom: var(--_7nmj7r1h);
  list-style: none;
}
.rq8dya9 .rq8dyaa::before {
  content: "■";
  display: inline-block;
  vertical-align: middle;
  font-size: 0.2em;
  margin-left: -4em;
  padding-right: 3em;
}
._1larm3x0 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
  padding-bottom: calc(var(--_7nmj7r21) - var(--_7nmj7r2j));
}
@media (min-width: 740px) {
  ._1larm3x0 {
    grid-template-columns: repeat(12, 1fr);
    padding-bottom: calc(var(--_7nmj7r23) - var(--_7nmj7r2j));
  }
  ._1larm3x1 {
    grid-column: 1 / span 3;
  }
  ._1larm3x2 {
    grid-column: 4 / span 9;
  }
}
@media (min-width: 1300px) {
  ._1larm3x3 {
    max-width: 66%;
  }
}
._15qimg80 {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: calc(var(--_7nmj7r1q) - var(--_7nmj7r2j));
}
._15qimg81 {
  margin-bottom: var(--_7nmj7r1k);
}
._15qimg83 {
  margin-bottom: var(--_7nmj7r1e);
}
._15qimg84 {
  padding: var(--_7nmj7r1a) 0;
}
@media (min-width: 1000px) {
  ._15qimg80 {
    grid-template-columns: repeat(12, 1fr);
    gap: var(--_7nmj7r1e);
    padding-bottom: calc(var(--_7nmj7r23) - var(--_7nmj7r2j));
  }
  ._15qimg81 {
    margin-bottom: 0;
    grid-column: 1 / span 3;
  }
  ._15qimg82 {
    grid-column: 7 / span 6;
  }
}
._14ki7h60 {
  display: grid;
  grid-template-columns: 1fr;
}
._14ki7h61 {
  grid-row: 1;
  display: flex;
  gap: var(--_7nmj7r1c);
  margin-block-end: var(--_7nmj7r1a);
}
._14ki7h62 {
  grid-row: 2;
  margin-bottom: var(--_7nmj7r1z);
}
._14ki7h63 {
  grid-row: 3;
}
@media (min-width: 740px) {
  ._14ki7h60 {
    grid-template-columns: repeat(12, 1fr);
    gap: var(--_7nmj7r1e);
  }
  ._14ki7h61 {
    grid-column: 7 / span 6;
  }
  ._14ki7h62 {
    grid-column: 7 / span 6;
  }
  ._14ki7h63 {
    grid-column: 7 / span 4;
  }
}
@media (min-width: 1000px) {
  ._14ki7h63 {
    grid-column: 7 / span 3;
  }
}
._7xgtxh1 {
  display: grid;
  background-color: var(--_7xgtxh0);
}
._7xgtxh2 {
  overflow: hidden;
}
._7xgtxh3 {
  padding: var(--_7nmj7r2i) 0 var(--_7nmj7r2j);
}
._7xgtxh5 {
  padding: var(--_7nmj7r2i) 0 var(--_7nmj7r2i);
}
._7xgtxh6 {
  width: 100%;
  margin: 0 auto;
  max-width: var(--_7nmj7r28);
  padding: 0 var(--_7nmj7r2h);
  display: grid;
}
.an8x9i0 {
  max-width: 1000px;
}
.an8x9i1 {
  color: var(--_7nmj7r8);
}
.an8x9i2 {
  color: var(--_7nmj7rp);
  margin-bottom: var(--_7nmj7r1k);
}
.an8x9i3 {
  min-height: calc(100vh - var(--_7nmj7r29));
}
@media (min-width: 1000px) {
  .an8x9i1 {
    padding-top: var(--_7nmj7r1e);
  }
  .an8x9i3 {
    min-height: calc(100vh - var(--_7nmj7r2c));
  }
}
.z6s3dv0 {
  display: grid;
  grid-gap: var(--_7nmj7r1n);
  gap: var(--_7nmj7r1n);
  padding-top: var(--_7nmj7r1h);
  padding-bottom: var(--_7nmj7r1e);
}
.z6s3dv1 {
  gap: var(--_7nmj7r1e);
}
.z6s3dv2 {
  display: none;
}
.z6s3dv3 {
  display: none;
}
.z6s3dv4 {
  display: block;
  width: 100%;
  grid-column: 9 / -1;
}
.z6s3dv5 {
  color: var(--_7nmj7ri);
  white-space: break-spaces;
}
.z6s3dv7 {
  color: var(--_7nmj7ri);
  white-space: break-spaces;
  grid-column: 7 / 9;
  margin-bottom: var(--_7nmj7r1c);
  margin-top: var(--_7nmj7r1n);
}
.z6s3dv8 {
  grid-column: 9 / 11;
}
.z6s3dv9 {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--_7nmj7r1a);
  gap: var(--_7nmj7r1a);
  grid-column: 9 / 11;
}
.z6s3dva {
  display: grid;
  grid-gap: var(--_7nmj7r18);
  gap: var(--_7nmj7r18);
}
.z6s3dvb {
  color: var(--_7nmj7ri);
  display: grid;
}
.z6s3dvc {
  width: 100%;
  height: auto;
  display: block;
  margin-block: var(--_7nmj7r1n);
}
.z6s3dve {
  color: var(--_7nmj7ri);
  align-self: start;
}
@media (min-width: 1000px) {
  .z6s3dv0 {
    padding-block: var(--_7nmj7r1j);
  }
  .z6s3dv1 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: var(--_7nmj7r1e);
    gap: var(--_7nmj7r1e);
  }
  .z6s3dv2 {
    display: block;
    grid-column: 1 / 3;
    max-width: 146px;
  }
  .z6s3dv3 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--_7nmj7r1e);
    gap: var(--_7nmj7r1e);
  }
  .z6s3dv5 {
    max-width: 255px;
    grid-column: 3 / 7;
  }
  .z6s3dv6 {
    max-width: 500px;
  }
  .z6s3dv7 {
    margin-top: 0;
  }
  .z6s3dvc {
    display: none;
  }
  .z6s3dvd {
    text-align: end;
    grid-column: 11 / -1;
  }
}
@media (min-width: 1300px) {
  .z6s3dv5 {
    grid-column: 4 / 6;
  }
  .z6s3dv6 {
    grid-column: 3 / 7;
  }
}
.m1nbje0 {
  display: grid;
  grid-gap: var(--_7nmj7r1n);
  gap: var(--_7nmj7r1n);
  padding-top: var(--_7nmj7r1h);
  padding-bottom: var(--_7nmj7r1e);
}
.m1nbje1 {
  display: flex;
  flex-direction: column;
  gap: var(--_7nmj7r1n);
}
.m1nbje2 {
  display: grid;
}
.m1nbje3 {
  display: none;
}
.m1nbje4 {
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
  color: var(--_7nmj7ri);
}
.m1nbje5 {
  white-space: break-spaces;
}
.m1nbje6 {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--_7nmj7r1a);
  gap: var(--_7nmj7r1a);
  grid-column: 7 / -1;
}
.m1nbje7 {
  display: grid;
  grid-gap: var(--_7nmj7r18);
  gap: var(--_7nmj7r18);
}
.m1nbje8 {
  color: var(--_7nmj7ri);
  display: grid;
}
.m1nbje9 {
  color: var(--_7nmj7ri);
  margin-bottom: var(--_7nmj7r17);
}
.m1nbjea {
  display: grid;
  grid-gap: var(--_7nmj7r1n);
  gap: var(--_7nmj7r1n);
}
.m1nbjeb {
  width: 100%;
  height: auto;
  display: block;
}
.m1nbjec {
  display: none;
}
.m1nbjee {
  color: var(--_7nmj7ri);
  align-self: start;
}
@media (min-width: 1000px) {
  .m1nbje1 {
    display: grid;
    flex-direction: unset;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--_7nmj7r1e);
    gap: var(--_7nmj7r1e);
  }
  .m1nbje2 {
    grid-column: 1 / 7;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: var(--_7nmj7r1k);
    justify-content: start;
  }
  .m1nbje3 {
    display: block;
    width: 146px;
  }
  .m1nbje5 {
    max-width: 270px;
  }
  .m1nbjea {
    grid-template-columns: repeat(12, 1fr);
    gap: var(--_7nmj7r1e);
  }
  .m1nbjeb {
    display: none;
  }
  .m1nbjec {
    display: block;
    grid-row: 1 / 1;
    width: 100%;
    grid-column: 9 / -1;
    align-self: end;
    margin-bottom: var(--_7nmj7r1e);
  }
  .m1nbjed {
    text-align: start;
    grid-column: 1 / 9;
    grid-row: 1 / 1;
    align-self: end;
  }
}
@media (min-width: 1300px) {
  .m1nbje2 {
    grid-column: 1 / 5;
  }
}
@media (min-width: 740px) {
  .m1nbje6 {
    grid-template-columns: repeat(3, 1fr);
  }
}
.wddnus0 {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--_7nmj7r1h);
  gap: var(--_7nmj7r1h);
  padding-block-start: var(--_7nmj7r1e);
  padding-block-end: var(--_7nmj7r1h);
}
.wddnus1 {
  margin-block-end: var(--_7nmj7r1a);
}
.wddnus2 {
  display: grid;
  grid-gap: 6px;
  gap: 6px;
  list-style: none;
}
.wddnus3 {
  display: inline-block;
}
.wddnus4 {
  display: flex;
}
.wddnus5 {
  flex: 1 0 50%;
}
@media (min-width: 1000px) {
  .wddnus0 {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    gap: var(--_7nmj7r1e);
    padding-block-end: var(--_7nmj7r1e);
  }
  .wddnus1 {
    max-width: 50%;
  }
}
.k0ztm90 {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
  min-height: 395px;
  padding-block-start: var(--_7nmj7r1e);
  padding-block-end: var(--_7nmj7r1h);
}
.k0ztm91 {
  margin-inline: calc(-1 * var(--_7nmj7r2h));
}
.k0ztm92 {
  display: none;
}
.k0ztm95 {
  color: var(--_7nmj7r8);
}
.k0ztm96 {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-gap: var(--_7nmj7r1g);
  gap: var(--_7nmj7r1g);
}
.k0ztm97 {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--_7nmj7r14);
}
.k0ztm98 {
  display: inline-block;
}
.k0ztm99 {
  margin-block-start: var(--_7nmj7r1e);
}
@media (min-width: 1000px) {
  .k0ztm90 {
    grid-template-columns: repeat(12, 1fr);
    padding-block-end: 0;
  }
  .k0ztm91 {
    display: none;
  }
  .k0ztm92 {
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin-inline: auto;
    pointer-events: none;
  }
  .k0ztm93 {
    position: relative;
    grid-column: 1 / span 6;
  }
  .k0ztm94 {
    max-width: 50%;
  }
  .k0ztm96 {
    grid-row: 1;
    grid-column: 7 / span 3;
  }
}
.gwfroy0 {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 495px;
  background-color: var(--_7nmj7rr);
  border-radius: var(--_7nmj7r2r);
}
.gwfroy0:has(:focus-visible) {
  outline: var(--_7nmj7rp) solid 1px;;
  outline-offset: 2px;
}
.gwfroy1 {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--_7nmj7r18);
  padding: var(--_7nmj7r1h) var(--_7nmj7r1e);
}
.gwfroy2 {
  opacity: 0;
  position: absolute;
  inset: 0;
}
.gwfroy4 {
  aspect-ratio: 1.16 / 1;
  width: 100%;
  max-height: 343px;
  background-color: var(--_7nmj7rq);
}
.gwfroy5 {
  color: var(--_7nmj7ri);
}
.gwfroy6 {
  position: absolute;
  top: 0;
  right: 0;
}
.gwfroy7 {
  color: var(--_7nmj7rp);
}
.gwfroy9 {
  position: relative;
  display: flex;
  gap: var(--_7nmj7r18);
}
@media (min-width: 1300px) {
  .gwfroy0 {
    height: 579px;
  }
}
@media (hover: hover) {
  .gwfroy3 {
    transition: transform 0.3s;
  }
  .gwfroy0:hover .gwfroy3 {
    transform: scale(1.2);
  }
  .gwfroy4 {
    overflow: hidden;
  }
  .gwfroy7 {
    color: var(--_7nmj7r6);
    transition: color 0.1s;
  }
  .gwfroy0:hover .gwfroy7 {
    color: var(--_7nmj7rp);
  }
}
@supports not (aspect-ratio: 1) {
  .gwfroy3 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .gwfroy4 {
    position: relative;
    padding-top: calc(100% / 1.16);
  }
}
.wzvy2a0 {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--_7nmj7r1p);
  gap: var(--_7nmj7r1p);
  padding-block-start: var(--_7nmj7r1e);
  padding-block-end: var(--_7nmj7r1k);
}
.wzvy2a2 {
  margin-bottom: var(--_7nmj7r1c);
}
.wzvy2a3 {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--_7nmj7r1c);
  gap: var(--_7nmj7r1c);
  margin-block-end: var(--_7nmj7r1c);
}
.wzvy2a4 {
  display: flex;
  flex-direction: column;
}
.wzvy2a5 {
  margin-bottom: var(--_7nmj7r1c);
}
.wzvy2a6 {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--_7nmj7r15);
  gap: var(--_7nmj7r15);
  list-style: none;
}
.wzvy2a7 {
  display: block;
  width: 100%;
}
.wzvy2a8 {
  display: block;
  margin-block-start: var(--_7nmj7r1c);
}
@media (min-width: 1000px) {
  .wzvy2a0 {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    gap: var(--_7nmj7r1e);
    padding-block-end: var(--_7nmj7r1e);
  }
  .wzvy2a3 {
    grid-auto-flow: column;
    gap: var(--_7nmj7r1e);
  }
  .wzvy2a4 {
    order: -1;
  }
  .wzvy2a7 {
    display: inline-block;
    width: auto;
  }
  .wzvy2a8 {
    margin-block-start: auto;
  }
}
.nqn4850 {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 24px;
  gap: 24px;
  padding-block-start: var(--_7nmj7r1e);
  padding-block-end: var(--_7nmj7r1h);
}
.nqn4851 {
  display: grid;
  grid-gap: var(--_7nmj7r1h);
  gap: var(--_7nmj7r1h);
}
.nqn4853 {
  display: none;
}
.nqn4854 {
  position: relative;
  grid-row: 1;
  grid-column: 1;
  max-width: 75%;
}
.nqn4855 {
  color: var(--_7nmj7r8);
}
.nqn4856 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: max-content;
  grid-gap: var(--_7nmj7r1e) var(--_7nmj7r18);
  gap: var(--_7nmj7r1e) var(--_7nmj7r18);
  list-style: none;
}
.nqn4857 {
  max-height: 100%;
  margin-bottom: var(--_7nmj7r1e);
}
.nqn4857 div:has(>img) {
  display: block;
  height: 33px;
}
.nqn4857 div > img {
  height: 100%!important;
  width: auto!important;
}
@media (min-width: 1000px) {
  .nqn4850 {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    min-height: 395px;
    padding-block-end: 0;
  }
  .nqn4852 {
    display: none;
  }
  .nqn4853 {
    display: block;
    grid-row: 1;
    grid-column: 1;
  }
  .nqn4854 {
    max-width: 50%;
  }
  .nqn4856 {
    grid-column: 2;
    gap: 24px;
    max-width: 66.66%;
  }
  .nqn4857 div:has(>img) {
    height: 40px;
  }
}
.qrhwic0 {
  color: var(--_7nmj7rp);
  border-top: 1px solid var(--_7nmj7rp);
}
.qrhwic1 {
  opacity: 0.35;
}
.qrhwic3 {
  display: block;
  min-height: var(--_7nmj7r2b);
  padding: var(--_7nmj7r1a);
}
.qrhwic4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: var(--_7nmj7r2b);
  padding: var(--_7nmj7r1a);
}
.qrhwic4[aria-expanded="false"] .qrhwic5 {
  border-bottom: 1px solid transparent;
}
.qrhwic4[aria-expanded="true"] .qrhwic5 {
  border-bottom: 1px solid var(--_7nmj7rp);
}
.qrhwic6 {
  transition: transform 0.2s;
  width: var(--_7nmj7r16);
  height: var(--_7nmj7r16);
}
.qrhwic4[aria-expanded="true"] .qrhwic6 {
  transform: rotate(180deg);
}
.qrhwic8 {
  display: grid;
  z-index: var(--_7nmj7rx);
}
.qrhwic9 {
  grid-template-rows: 1fr;
}
.qrhwica {
  grid-template-rows: 0fr;
}
@media (min-width: 1000px) {
  .qrhwic0 {
    border-top: none;
  }
  .qrhwic3 {
    min-height: auto;
    padding: 0;
  }
  .qrhwic4 {
    justify-content: flex-start;
    gap: var(--_7nmj7r14);
    min-height: auto;
    height: auto;
    padding: 0;
  }
  .qrhwic8 {
    position: absolute;
    top: var(--qrhwic7);
    left: 0;
    right: 0;
    transition: opacity 0.2s;
    transform-origin: top center;
  }
  .qrhwic9 {
    opacity: 1;
    pointer-events: auto;
  }
  .qrhwica {
    grid-template-rows: 1fr;
    opacity: 0;
    pointer-events: none;
  }
}
.g14ugg0 {
  width: 66%;
  max-width: 232px;
}
.g14ugg1 {
  display: grid;
  grid-gap: 24px;
  gap: 24px;
  grid-template-columns: 25% 1fr 1fr 1fr;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  list-style: none;
}
.bhf6rz0 {
  max-width: var(--_7nmj7r28);
  height: var(--_7nmj7r2e);
  margin: 0 auto;
  padding: 16px 24px;
}
.bhf6rz1 {
  display: grid;
  grid-gap: 24px;
  gap: 24px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  list-style: none;
}
.bhf6rz2 {
  width: 66%;
  max-width: 120px;
}
.cmt7zl0 {
  color: var(--_5sls2t4);
}
.hx9r280 {
  position: relative;
  transition: background 0.3s;
}
.hx9r281 {
  background: var(--_7nmj7rs);
}
.hx9r282 {
  background: var(--_5sls2t0, var(--_7nmj7ri));
}
.hx9r283 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 72px 16px;
}
.hx9r284 {
  width: 100%;
  max-width: 130px;
}
@media (min-width: 1000px) {
  .hx9r283 {
    display: none;
  }
}
._177hpkh0 {
  overflow: hidden;
  z-index: var(--_7nmj7rt);
  display: grid;
  background-color: inherit;
  transition: opacity 0.3s;
}
._177hpkh1 {
  display: flex;
  flex-direction: column;
  list-style: none;
}
._177hpkh3 {
  position: fixed;
  top: var(--_177hpkh2);
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: var(--_7nmj7r1e);
  overflow: auto;
  z-index: var(--_7nmj7rz);
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
._177hpkh4 {
  width: 100%;
  background-color: var(--_7nmj7rs);
  transition: opacity 0.3s ease-in-out;
}
._177hpkh5 {
  opacity: 1;
}
._177hpkh6 {
  opacity: 0;
}
@media (min-width: 1000px) {
  ._177hpkh0 {
    display: none;
  }
}
@media (min-width: 740px) {
  ._177hpkh3 {
    display: none;
  }
}
.rsu5080 {
  color: var(--_7nmj7rp);
  border-top: 1px solid var(--_7nmj7rp);
}
.rsu5081 {
  opacity: 0.35;
}
.rsu5083 {
  display: block;
  min-height: var(--_7nmj7r2b);
  padding: var(--_7nmj7r1a);
}
.rsu5084 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: var(--_7nmj7r2b);
  padding: var(--_7nmj7r1a);
}
.rsu5085 {
  display: flex;
  justify-content: space-between;
}
.rsu5084[aria-expanded="true"] .rsu5086 {
  border-bottom: 1px solid var(--_7nmj7rp);
}
.rsu5087 {
  transition: transform 0.2s;
  width: var(--_7nmj7r1a);
  height: var(--_7nmj7r1a);
}
.rsu5084[aria-expanded="true"] .rsu5087 {
  transform: rotate(180deg);
}
.rsu5089 {
  display: grid;
  z-index: var(--_7nmj7rx);
  background-color: var(--_7nmj7rh);
}
.rsu508a {
  grid-template-rows: 1fr;
}
.rsu508b {
  grid-template-rows: 0fr;
}
@media (min-width: 1000px) {
  .rsu508a {
    opacity: 1;
    pointer-events: auto;
  }
  .rsu508b {
    grid-template-rows: 1fr;
    opacity: 0;
    pointer-events: none;
  }
}
._1fl6p430 {
  display: flex;
  padding: 8px;
  margin: -8px;
  color: var(--_5sls2t4);
  -webkit-tap-highlight-color: transparent;
}
._1fl6p431 {
  transition: transform 0.3s;
}
._1fl6p432 {
  transform-origin: 26px 15px;
}
._1fl6p433 {
  transform: rotate(-45deg);
}
._1fl6p434 {
  transform: rotate(0);
}
._1fl6p435 {
  transition: transform 0.3s, opacity 0.3s;
  transform-origin: center;
}
._1fl6p436 {
  transform: scaleX(0);
  opacity: 0;
}
._1fl6p437 {
  transform: scaleX(1);
  opacity: 1;
}
._1fl6p438 {
  transform-origin: 28px 24px;
}
._1fl6p439 {
  transform: rotate(45deg);
}
._1fl6p43a {
  transform: rotate(0);
}
._11qy5r10 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-bottom: 1px solid var(--_7nmj7rg);
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: var(--_7nmj7rx);
}
._11qy5r11 {
  transform: translateY(0);
}
._11qy5r12 {
  transform: translateY(-100%);
}
._16r68uy0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--_7nmj7r2a);
  padding: 10px 16px;
}
._16r68uy1 {
  max-width: 120px;
  width: 100%;
}
@media (min-width: 1000px) {
  ._16r68uy0 {
    display: none;
  }
}
._6ujayw0 {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--_7nmj7r1e);
  -moz-column-gap: var(--_7nmj7r1e);
       column-gap: var(--_7nmj7r1e);
  grid-row-gap: var(--_7nmj7r1e);
  row-gap: var(--_7nmj7r1e);
  max-width: var(--_7nmj7r28);
  margin: 0 auto;
  padding-block: calc(var(--_7nmj7r1h) - var(--_7nmj7r2i)) calc(var(--_7nmj7r1z) - var(--_7nmj7r2j));
  list-style: none;
}
@media (min-width: 740px) {
  ._6ujayw0 {
    grid-template-columns: repeat(2, 1fr);
    row-gap: var(--_7nmj7r1j);
  }
}
@media (min-width: 1300px) {
  ._6ujayw0 {
    grid-template-columns: repeat(4, 1fr);
    padding-block: calc(var(--_7nmj7r22) - var(--_7nmj7r2i)) calc(var(--_7nmj7r1z) - var(--_7nmj7r2j));
  }
}
._6sgi280 {
  display: grid;
}
._6sgi281 {
  display: block;
}
@media (min-width: 1000px) {
  ._6sgi280 {
    grid-template-columns: repeat(12, 1fr);
    -moz-column-gap: var(--_7nmj7r1e);
         column-gap: var(--_7nmj7r1e);
  }
  ._6sgi281 {
    grid-column: 7 / 13;
  }
}
._102uyxe3 {
  --_102uyxe0: var(--_7nmj7r18);
  --_102uyxe1: var(--_7nmj7r1k);
  --_102uyxe2: calc(2*var(--_7nmj7r1k));
  max-width: 800px;
}
._102uyxe3 > :first-child {
  margin-top: var(--_102uyxe1);
}
._102uyxe3 > :last-child {
  margin-bottom: var(--_102uyxe2);
}
._102uyxe4 {
  margin-top: var(--_102uyxe1);
  margin-bottom: var(--_102uyxe0);
}
._102uyxe8 {
  margin-bottom: var(--_7nmj7r1i);
}
._102uyxe9 {
  margin-bottom: var(--_7nmj7r1e);
}
._102uyxea {
  margin-bottom: var(--_7nmj7r1a);
}
._102uyxec {
  font-style: italic;
}
._102uyxed {
  text-decoration: underline;
}
._102uyxee {
  font-weight: bold;
}
._102uyxef {
  color: var(--_7nmj7r4);
}
._102uyxeg {
  margin-bottom: var(--_7nmj7r1e);
  padding-left: var(--_7nmj7r1e);
}
li ._102uyxeg {
  margin-bottom: var(--_7nmj7r14);
}
._102uyxeh {
  border: 2px solid var(--_7nmj7rl);
  margin: var(--_102uyxe1) auto var(--_102uyxe0);
  width: 100%;
}
@media (min-width: 1300px) {
  ._102uyxe3 {
    --_102uyxe0: var(--_7nmj7r18);
    --_102uyxe1: var(--_7nmj7r1q);
    --_102uyxe2: calc(2*var(--_7nmj7r1q));
  }
}
.h6m4b80 {
  margin: var(--_102uyxe1) 0 var(--_102uyxe0);
}
.h6m4b81 {
  margin-top: var(--_7nmj7r18);
  color: var(--_7nmj7rp);
}
._1hvxxat0 {
  width: 100%;
  margin: var(--_7nmj7r1k) 0;
}
._1hvxxat0::before {
  content: "";
  display: block;
  height: var(--_7nmj7r14);
  background-color: var(--_7nmj7rl);
  margin-block-end: var(--_7nmj7r1b);
}
._1hvxxat1 {
  position: relative;
  margin: var(--_7nmj7r18) var(--_7nmj7r1e) var(--_7nmj7r18) 0;
}
._1hvxxat1::before, ._1hvxxat1::after {
  quotes: auto;
}
._1hvxxat1::before {
  content: open-quote;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}
._1hvxxat1::after {
  content: close-quote;
}
._1hvxxat2 {
  display: grid;
  padding: var(--_7nmj7r1a) 0 var(--_7nmj7r12);
}
@media (min-width: 1300px) {
  ._1hvxxat0 {
    margin: var(--_7nmj7r1q) 0;
  }
}
._1armnyg0:first-child {
  margin-block-start: var(--_7nmj7r1q);
}
._1armnyg0:last-child {
  margin-block-end: var(--_7nmj7r1v);
}
._1armnyg1 {
  max-width: 800px;
}
._1armnyg3 {
  margin-block: var(--_7nmj7r1k) var(--_7nmj7r15);
}
._1armnyg4 {
  margin-block: var(--_7nmj7r1k) var(--_7nmj7r1q);
}
._1armnyg5 {
  margin-bottom: var(--_7nmj7r1i);
}
._1armnyg6 {
  margin-bottom: var(--_7nmj7r1e);
}
._1armnyg7 {
  margin-bottom: var(--_7nmj7r1a);
}
._1armnyg9 {
  font-style: italic;
}
._1armnyga {
  text-decoration: underline;
}
._1armnygb {
  font-weight: bold;
}
._1armnygc {
  color: var(--_7nmj7r4);
}
._1armnygd {
  margin-bottom: var(--_7nmj7r1e);
  padding-left: var(--_7nmj7r1e);
}
li ._1armnygd {
  margin-bottom: var(--_7nmj7r14);
}
._1armnyge {
  margin-bottom: var(--_7nmj7r1h);
  list-style: none;
}
._1armnyge ._1armnygf::before {
  content: "■";
  display: inline-block;
  vertical-align: middle;
  font-size: 0.2em;
  margin-left: -4em;
  padding-right: 3em;
}
@media (min-width: 1300px) {
  ._1armnyg0:last-child {
    margin-block-end: calc(2 * var(--_7nmj7r1q));
  }
  ._1armnyg3 {
    margin-block: var(--_7nmj7r1q) var(--_7nmj7r15);
  }
  ._1armnyg4 {
    margin-block: var(--_7nmj7r1q);
  }
}
@media (min-width: 1000px) {
  ._1armnyg1 {
    margin-left: calc(50% + 24px / 2);
  }
}
._1os7tlg0 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
  margin-block: var(--_7nmj7r1e) var(--_7nmj7r1k);
}
._1os7tlg1 {
  overflow: hidden;
  border-radius: 6px;
}
@media (min-width: 1000px) {
  ._1os7tlg0 {
    grid-template-columns: 1fr 1fr;
  }
}
._12dy8rf0 {
  margin-block: var(--_7nmj7r1k) var(--_7nmj7r1q);
}
@media (min-width: 1300px) {
  ._12dy8rf0 {
    margin-block: var(--_7nmj7r1q);
  }
}
.dj4ogk0 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--_7nmj7r1q);
  gap: var(--_7nmj7r1q);
}
.dj4ogk2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--_7nmj7r1j);
  gap: var(--_7nmj7r1j);
}
@media (min-width: 1000px) {
  .dj4ogk0 {
    grid-template-columns: 1fr 1fr;
    gap: var(--_7nmj7r1e);
  }
}
@media (min-width: 740px) {
  .dj4ogk1 {
    max-width: 50%;
  }
  .dj4ogk2 {
    grid-template-columns: 1fr 1fr;
    gap: var(--_7nmj7r1j) var(--_7nmj7r1c);
  }
}
.jw655d0 {
  width: 100%;
  margin: var(--_7nmj7r1k) 0;
}
.jw655d0::before {
  content: "";
  display: block;
  height: var(--_7nmj7r14);
  background-color: var(--_7nmj7rl);
  margin-block-end: var(--_7nmj7r1b);
}
.jw655d1 {
  position: relative;
  margin: var(--_7nmj7r18) var(--_7nmj7r1e) var(--_7nmj7r18) 0;
}
.jw655d1::before, .jw655d1::after {
  quotes: auto;
}
.jw655d1::before {
  content: open-quote;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}
.jw655d1::after {
  content: close-quote;
}
.jw655d2 {
  display: grid;
  padding: var(--_7nmj7r1a) 0 var(--_7nmj7r12);
}
@media (min-width: 1300px) {
  .jw655d0 {
    margin: var(--_7nmj7r1q) 0;
  }
}
._17szd3d0 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
  width: 100%;
  margin: 0 auto;
  max-width: var(--_7nmj7r28);
}
._17szd3d1 {
  display: grid;
}
._17szd3d2 {
  aspect-ratio: 1;
}
._17szd3d3 {
  display: grid;
  padding-top: var(--_7nmj7r2i);
  padding-bottom: var(--_7nmj7r2j);
}
._17szd3d6 {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
  padding-right: var(--_7nmj7r2h);
  padding-left: var(--_7nmj7r2h);
}
._17szd3d7 {
  display: flex;
  gap: var(--_7nmj7r1h);
  flex-direction: column;
}
._17szd3d8 {
  display: grid;
}
._17szd3d9 {
  grid-column: span 5;
}
._17szd3da {
  grid-column: span 3;
}
._17szd3db {
  display: grid;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
}
._17szd3dc {
  display: grid;
  grid-row-gap: var(--_7nmj7r1a);
  row-gap: var(--_7nmj7r1a);
  grid-template-rows: 1fr auto;
}
@media (min-width: 1000px) {
  ._17szd3d0 {
    grid-template-rows: 1fr;
    gap: unset;
  }
  ._17szd3d1 {
    grid-column: 1;
    grid-row: 1;
    grid-template-columns: repeat(12, 1fr);
    -moz-column-gap: var(--_7nmj7r1e);
         column-gap: var(--_7nmj7r1e);
  }
  ._17szd3d2 {
    aspect-ratio: auto;
    grid-column: 1 / 7;
  }
  ._17szd3d3 {
    grid-column: 1;
    grid-row: 1;
    grid-template-columns: repeat(12, 1fr);
    -moz-column-gap: var(--_7nmj7r1e);
         column-gap: var(--_7nmj7r1e);
  }
  ._17szd3d5 {
    padding-bottom: 240px;
  }
  ._17szd3d6 {
    gap: var(--_7nmj7r1t);
    grid-column: 7 / -1;
    padding-left: unset;
  }
  ._17szd3d8 {
    grid-template-columns: repeat(6, 1fr);
    gap: var(--_7nmj7r1e);
  }
  ._17szd3db {
    grid-template-columns: repeat(6, 1fr);
    padding-bottom: var(--_7nmj7r1e);
  }
  ._17szd3dc {
    grid-column: span 2;
    row-gap: var(--_7nmj7r1i);
  }
  ._17szd3dd {
    grid-column: span 2;
  }
}
@media (min-width: 1300px) {
  ._17szd3d9 {
    grid-column: span 3;
  }
}
._148pgcj0 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: var(--_7nmj7r18);
  gap: var(--_7nmj7r18);
}
._148pgcj2 {
  padding-bottom: calc(var(--_7nmj7r21) - var(--_7nmj7r2j));
}
._148pgcj4 {
  grid-column: 1 / span 2;
}
._148pgcj5 {
  grid-column: 1 / span 2;
}
._148pgcj6 {
  padding-bottom: var(--_7nmj7r1m);
}
._148pgcj7 {
  padding-bottom: var(--_7nmj7r1k);
}
._148pgcj8 {
  grid-row: 2;
  grid-column: 1 / span 4;
}
._148pgcj9 {
  display: flex;
  flex-direction: column;
}
._148pgcja {
  margin-block-end: var(--_7nmj7r1j);
}
._148pgcjb {
  margin-block-end: var(--_7nmj7r1q);
}
._148pgcjc {
  margin-top: var(--_7nmj7r1h);
}
._148pgcjd {
  margin-block-end: var(--_7nmj7r1a);
}
._148pgcje {
  display: grid;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
}
._148pgcjf {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--_7nmj7r1e);
}
._148pgcjg {
  display: block;
  margin-top: var(--_7nmj7r1e);
}
._148pgcjh {
  display: none;
}
@media (min-width: 1000px) {
  ._148pgcj0 {
    grid-template-columns: repeat(12, 1fr);
    gap: var(--_7nmj7r1e);
  }
  ._148pgcj2 {
    padding-bottom: var(--_7nmj7r12);
  }
  ._148pgcj3 {
    padding-bottom: 0;
  }
  ._148pgcj4 {
    grid-column: 1 / span 2;
  }
  ._148pgcj5 {
    grid-column: 5 / span 2;
  }
  ._148pgcj8 {
    grid-row: 1;
    grid-column: 7 / span 6;
  }
  ._148pgcja {
    margin-block-end: var(--_7nmj7r1z);
  }
  ._148pgcjb {
    margin-block-end: var(--_7nmj7r1t);
  }
  ._148pgcje {
    grid-template-columns: 1fr 1fr;
  }
  ._148pgcjg {
    display: none;
  }
  ._148pgcjh {
    display: block;
  }
}
._16e5orm0 {
  padding-bottom: calc(var(--_7nmj7r1k) - var(--_7nmj7r2j));
}
._16e5orm1 {
  margin-bottom: var(--_7nmj7r1j);
  grid-column: 1 / -1;
}
._16e5orm2 {
  grid-column: 1 / -1;
}
@media (min-width: 740px) {
  ._16e5orm1 {
    grid-column: 1 / 7;
  }
  ._16e5orm2 {
    grid-column: 7 / -1;
  }
}
._1zmdip0 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
._1zmdip1 {
  padding-block: var(--_7nmj7r1k);
}
._1zmdip4 {
  box-shadow: 0 -0.5px 0 var(--_7nmj7re);
}
._1zmdip6 {
  grid-column: 1 / -1;
  margin-bottom: var(--_7nmj7r1e);
}
._1zmdip8 {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
}
._1zmdip9 {
  position: relative;
  padding-top: 51.282051282051285%;
  grid-column: 1 / 5;
}
._1zmdipa {
  position: relative;
  padding-top: 51.282051282051285%;
  overflow: hidden;
  background-color: var(--_7nmj7rg);
  display: grid;
  grid-column: 1 / -1;
}
._1zmdipb {
  width: 100%;
}
._1zmdipc {
  width: 100%;
  max-width: 230px;
  margin: var(--_7nmj7r1g) auto 0;
  justify-self: center;
}
@media (min-width: 740px) {
  ._1zmdip1 {
    padding-block: var(--_7nmj7r1e) var(--_7nmj7r1q);
  }
  ._1zmdip2 {
    grid-column: 1 / 13;
  }
  ._1zmdip6 {
    grid-column: 1 / 6;
    margin-bottom: var(--_7nmj7r12);
  }
  ._1zmdip8 {
    grid-template-columns: 1fr 1fr;
    grid-column: 7 / 13;
  }
  ._1zmdip9 {
    grid-column: 1 / 10;
    padding-top: 59.880239520958085%;
  }
  ._1zmdipa {
    padding: var(--_7nmj7r12) var(--_7nmj7r1e);
    grid-column: 10 / 13;
    border-left: 1px solid var(--_7nmj7re);
  }
  ._1zmdipc {
    position: relative;
    max-width: 340px;
    margin-top: var(--_7nmj7r12);
    align-items: center;
  }
}
@media (min-width: 1000px) {
  ._1zmdip4 {
    box-shadow: none;
    border-top: 1px solid var(--_7nmj7re);
  }
  ._1zmdipa {
    padding: var(--_7nmj7r12) var(--_7nmj7r1k);
  }
}
@media (min-width: 1900px) {
  ._1zmdipa {
    padding: var(--_7nmj7r1k);
  }
  ._1zmdipc {
    max-width: 495px;
  }
}
.uku8sr1 {
  grid-column: 1 / -1;
  color: var(--_7nmj7rp);
  margin-bottom: var(--_7nmj7r1k);
}
.uku8sr2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
  grid-column: 1 / -1;
  color: var(--_7nmj7r0);
}
.uku8sr3 {
  display: flex;
  flex-direction: column;
  gap: var(--_7nmj7r16);
}
.uku8sr5 {
  display: flex;
  flex-direction: column;
  gap: var(--_7nmj7r1e);
}
.uku8sr6 {
  aspect-ratio: 1 / 1;
}
.uku8sr7 {
  list-style: none;
}
.uku8sr7 .uku8sr8::before {
  content: "■";
  display: inline-block;
  vertical-align: middle;
  font-size: 0.2em;
  margin-left: -4em;
  padding-right: 3em;
}
@media (min-width: 740px) {
  .uku8sr1 {
    grid-column: 1 / 3;
    margin-bottom: 0;
  }
  .uku8sr2 {
    grid-column: 4 / -1;
    grid-template-columns: repeat(3, 1fr);
  }
}
._1qow30a0 {
  grid-column: 1 / 5;
}
._1qow30a1 {
  grid-column: 1 / 5;
}
._1qow30a2 {
  display: grid;
  grid-column: span 4;
}
._1qow30a3 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  padding: var(--_7nmj7r1e) 0 var(--_7nmj7r1k) 0;
  grid-row-gap: var(--_7nmj7r1e);
  row-gap: var(--_7nmj7r1e);
}
@media (min-width: 740px) {
  ._1qow30a0 {
    grid-column: 1 / 4;
  }
  ._1qow30a1 {
    grid-column: 10 / 13;
  }
  ._1qow30a2 {
    grid-column: 2 / 12;
    grid-row: 2;
    margin-top: var(--_7nmj7r1o);
  }
  ._1qow30a3 {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto;
    padding: var(--_7nmj7r1e) 0 var(--_7nmj7r1q) 0;
    row-gap: 0;
  }
}
.b0txif0 {
  padding-block: var(--_7nmj7r1e) var(--_7nmj7r1k);
}
.b0txif1 {
  grid-column: 1 / span 4;
  margin-bottom: var(--_7nmj7r1n);
}
.b0txif2 {
  grid-row: 2;
  grid-column: 1 / span 4;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
}
.b0txif4 {
  display: grid;
  grid-gap: var(--_7nmj7r1e);
  gap: var(--_7nmj7r1e);
  grid-template-columns: 1fr;
  padding-block: var(--_7nmj7r1e) calc(var(--_7nmj7r1k) - var(--_7nmj7r2j));
  list-style: none;
}
.b0txif5 {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--_7nmj7rr);
  border-radius: var(--_7nmj7r2r);
}
.b0txif7 {
  flex: 1 0;
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: var(--b0txif6);
}
.b0txif8 {
  display: flex;
  align-items: center;
  aspect-ratio: 1.08 / 1;
}
.b0txif9 {
  display: grid;
  align-content: space-between;
  grid-gap: var(--_7nmj7r1k);
  gap: var(--_7nmj7r1k);
  padding-inline: var(--_7nmj7r1e);
  padding-block: var(--_7nmj7r1h);
}
.b0txifa:focus {
  outline: none;
}
.b0txifb {
  position: absolute;
  inset: 0;
  opacity: 0;
  outline: none;
}
@media (min-width: 740px) {
  .b0txif1 {
    grid-column: 1 / span 6;
    margin-bottom: var(--_7nmj7r12);
  }
  .b0txif2 {
    grid-row: 1;
    grid-column: 7 / -1;
    grid-template-columns: 1fr 1fr;
  }
  .b0txif4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1000px) {
  .b0txif4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .b0txif9 {
    gap: var(--_7nmj7r1h);
  }
  .b0txifa:focus {
    outline: var(--_7nmj7rp) solid 1px;;
    outline-offset: 2px;
  }
  .b0txifb {
    display: none;
  }
}
@media (min-width: 1300px) {
  .b0txif1 {
    grid-column: 1 / span 3;
    margin-bottom: var(--_7nmj7r12);
  }
  .b0txif9 {
    padding-inline: var(--_7nmj7r1h);
  }
}
@media (max-width: 999px) {
  .b0txif5:has(:focus-visible) {
    outline: var(--_7nmj7rp) solid 1px;;
    outline-offset: 2px;
  }
}
.ht61y80 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--_7nmj7r1c);
  gap: var(--_7nmj7r1c);
}
.ht61y81 {
  display: flex;
  flex-direction: column;
  background-color: var(--_7nmj7rr);
  border-radius: var(--_7nmj7r2m);
  padding: var(--_7nmj7r1e) var(--_7nmj7r1j) var(--_7nmj7r1e) var(--_7nmj7r1e);
  gap: var(--_7nmj7r18);
}
.ht61y83 {
  width: 32px;
  height: 32px;
  border-radius: var(--_7nmj7r2o);
  overflow: hidden;
}
.ht61y85 {
  display: flex;
  flex-direction: column;
  gap: var(--_7nmj7r14);
}
.ht61y86 {
  color: var(--_7nmj7r6);
}
@media (min-width: 740px) {
  .ht61y80 {
    grid-template-columns: repeat(2, 1fr);
  }
  .ht61y81 {
    gap: var(--_7nmj7r1e);
  }
  .ht61y83 {
    width: 64px;
    height: 64px;
  }
}
@media (min-width: 1000px) {
  .ht61y80 {
    grid-template-columns: repeat(3, 1fr);
  }
  .ht61y81 {
    gap: var(--_7nmj7r1i);
  }
  .ht61y83 {
    width: 96px;
    height: 96px;
  }
}
@media (min-width: 1300px) {
  .ht61y80 {
    grid-template-columns: repeat(4, 1fr);
  }
  .ht61y81 {
    gap: var(--_7nmj7r1k);
  }
  .ht61y83 {
    width: 128px;
    height: 128px;
  }
}
._9vkvpx0 {
  padding-bottom: calc(var(--_7nmj7r1q) - var(--_7nmj7r2j));
}
._9vkvpx1 {
  grid-column: 1 / -1;
}
._9vkvpx2 {
  display: flex;
  flex-direction: column;
  gap: var(--_7nmj7r1e);
  list-style: none;
  grid-column: 1 / -1;
  padding: var(--_7nmj7r1e) 0;
}
._9vkvpx3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--_7nmj7r1e) 0;
  border-bottom: 1px solid var(--_7nmj7rp);
}
._9vkvpx4 {
  color: var(--_7nmj7rp);
  padding: var(--_7nmj7r1e) 0;
}
._9vkvpx5 {
  display: flex;
  flex-direction: column;
  list-style: none;
}
._9vkvpx6 {
  color: var(--_7nmj7rp);
}
._9vkvpx7 {
  opacity: 0.5;
  transition: opacity 0.2s;
}
._9vkvpx3:hover ._9vkvpx7 {
  opacity: 1;
}
@media (min-width: 1000px) {
  ._9vkvpx0 {
    padding-bottom: calc(var(--_7nmj7r23) - var(--_7nmj7r2j));
  }
  ._9vkvpx1 {
    grid-column: 1 / 4;
  }
  ._9vkvpx2 {
    grid-column: 7 / -1;
  }
}
._19hpofo0 {
  grid-column: span 4;
  aspect-ratio: 1.21 / 1;
  position: relative;
  top: 0;
}
._19hpofo1 {
  display: grid;
  grid-column: span 4;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: var(--_7nmj7r1e);
  -moz-column-gap: var(--_7nmj7r1e);
       column-gap: var(--_7nmj7r1e);
  padding: 0 0 var(--_7nmj7r1q) 0;
}
._19hpofo2 {
  grid-column: span 4;
}
._19hpofo3 {
  grid-column: span 4;
  margin-bottom: var(--_7nmj7r1a);
}
._19hpofo4 {
  margin-bottom: var(--_7nmj7r1h);
}
._19hpofo5 {
  grid-column: span 4;
  margin-bottom: var(--_7nmj7r1n);
}
@media (min-width: 740px) {
  ._19hpofo0 {
    position: sticky;
    grid-column: span 4;
  }
  ._19hpofo1 {
    grid-column: 7 / 13;
    grid-template-columns: repeat(6, 1fr);
    padding: var(--_7nmj7r1h) 0 var(--_7nmj7r1h) 0;
  }
  ._19hpofo2 {
    grid-column: span 6;
  }
  ._19hpofo3 {
    grid-column: span 6;
  }
  ._19hpofo5 {
    grid-column: span 6;
  }
}
@media (min-width: 1000px) {
  ._19hpofo1 {
    padding: var(--_7nmj7r1h) 0 var(--_7nmj7r1t) 0;
  }
  ._19hpofo5 {
    margin-bottom: var(--_7nmj7r1t);
  }
}
@media (min-width: 1300px) {
  ._19hpofo2 {
    grid-column: span 3;
  }
}
._1te9az11 {
  grid-column: 1 / -1;
}
